import { createMuiTheme } from '@material-ui/core/styles';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

import paletteNight from './night/palette';
import typographyNight from './night/typography';
import overridesNight from './night/overrides';

export const theme = createMuiTheme({
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

export const themeDark = createMuiTheme({
  palette: paletteNight,
  typography: typographyNight,
  overrides: overridesNight,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
// export default theme;
