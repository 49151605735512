import React from 'react';
import AppendHead from 'react-append-head';
import ReactDOM from 'react-dom';
import App from './App';

window.ENDPOINT =
  process.env.NODE_ENV !== 'production' ? 'http://localhost:5000' : 'https://api.directachile.cl';

window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer.push(arguments);
}

const AddHeadScript = () => {
  if (process.env.NODE_ENV !== 'production') return false;

  return (
    <AppendHead>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-BW3LKL2SM2"></script>
      <script>{(gtag('js', new Date()), gtag('config', 'G-BW3LKL2SM2'))}</script>
    </AppendHead>
  );
};

ReactDOM.render(
  <React.Fragment>
    <AddHeadScript />
    <App />
  </React.Fragment>,
  document.getElementById('root')
);
