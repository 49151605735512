import { colors, fade } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: colors.blue[800],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  secondary: {
    contrastText: white,
    dark: colors.pink[900],
    main: colors.pink[600],
    light: colors.pink[400],
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.lightBlue[900],
    main: colors.lightBlue[600],
    light: colors.lightBlue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.grey[900],
    secondary: colors.grey[600],
    link: colors.blue[600],
  },
  background: {
    default: colors.grey[50],
    paper: white,
  },
  icon: colors.grey[500],
  iconAdd: colors.lightGreen['600'],
  iconHelp: colors.yellow['700'],
  iconInfo: colors.lightBlue['200'],
  iconAddPercentage: colors.blueGrey['400'],
  iconEdit: colors.orange[400],
  iconLock: colors.deepOrange[500],
  iconRemove: colors.red['500'],
  iconDisabled: colors.grey['400'],
  table: colors.lightBlue['600'],
  divider: colors.grey[200],
  avatar: colors.grey[300],
  topbar: {
    selected: colors.blueGrey['50'],
    selectedCheckbox: white,
    iconActionsIn: colors.teal['A700'],
    background: 'rgb(35, 48, 68)',
    contrastText: colors.grey[50],
    iconDefault: colors.grey[50],
  },
  menu: {
    text: colors.grey[50],
    background: 'rgb(35, 48, 68)',
    hover: `${fade(colors.blueGrey[800], 0.4)}`,
    active: `${fade(colors.blueGrey[700], 0.4)}`,
    textActive: colors.cyan['A200'],
  },
  avatarHover: `${fade(colors.grey[800], 0.75)}`,
};
