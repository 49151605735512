import { fade, lighten } from '@material-ui/core';
import palette from '../palette';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  root: {
    '&$selected': {
      backgroundColor: fade(palette.background.default, 0.7),
    },

    '& .box-container-icon': {
      opacity: '0',
    },

    '&.rowAction.Mui-selected': {
      '& .box-container-icon': {
        opacity: '1',
      },
    },

    '&.rowTableHover': {
      borderTop: `2px solid ${lighten(palette.table, 0.8)}`,
    },

    '&$hover': {
      '&:hover': {
        backgroundColor: fade(palette.background.default, 0.3),

        '& .box-container-icon': {
          opacity: '1',
        },
      },
    },
  },
};
