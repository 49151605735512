import MuiDialogContent from './MuiDialogContent';
import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableRow from './MuiTableRow';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';
import palette from '../palette';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  MuiDialogContent,
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableRow,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,

  MuiDialogTitle: {
    root: {
      backgroundColor: palette.topbar.background,
      color: palette.topbar.contrastText,

      '& .MuiTypography-root, .MuiButtonBase-root': {
        color: palette.topbar.contrastText,
      },

      '& .MuiButtonBase-root': {
        '&.Mui-disabled': {
          color: 'rgba(230, 230, 230, 0.4)',
        },
      },
    },
  },

  MuiTable: {
    stickyHeader: {
      borderCollapse: 'collapse',
    },
  },

  MuiInputBase: {
    input: {
      '&.Mui-disabled': {
        cursor: 'not-allowed !important',
      },
    },
  },

  MuiListItem: {
    root: {
      '& .MuiIconButton-root': {
        padding: '7px',
      },

      '& .MuiIcon-root': {
        fontSize: '1.2rem',
      },
    },
  },
};
