import { fade } from '@material-ui/core';
import palette from '../palette';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  root: {
    color: palette.icon,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.13)',
    },

    '&.Mui-disabled': {
      border: `1px solid transparent`,
      color: 'rgba(255, 255, 255, 0.36)',
    },
  },

  colorPrimary: {
    '&:hover': {
      backgroundColor: `${fade(palette.primary.main, 0.15)}`,
    },
  },
};
