import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Icon,
  Link,
  Hidden,
  Tooltip,
  Avatar,
  useTheme,
} from '@material-ui/core';
import { colors } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { Link as RouterLink, useHistory } from 'react-router-dom';
import { grey, lightBlue, red, yellow } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.palette.topbar.boxShadow,
    color: colors.grey[800],
    background: theme.palette.topbar.background,
    borderBottom: `0px solid ${colors.grey[200]}`,
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    color: theme.palette.topbar.contrastText,

    '& .MuiIcon-root': {
      fontSize: 25.5,
    },
  },
  avatar: {
    borderRadius: 0,
    width: 200,
    height: 'auto',
    // marginLeft: theme.spacing(2),

    '& img': {
      width: 45,
      backgroundColor: 'transparent',
    },
  },
  menu: {
    height: 64,
  },
  active: {
    borderBottom: '3px solid transparent',
  },
  itemMenu: {
    margin: theme.spacing(0, 2.5),
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderBottom: '3px solid transparent',
    // color: grey[800],
    fontSize: 12,

    '& > h6': {
      fontWeight: '700 !important',
    },

    '&:hover': {
      borderBottom: `3px solid ${colors.grey[300]}`,
    },

    '&$active': {
      cursor: 'default',
      // borderBottom: `3px solid ${theme.palette.tbk.main}`,
    },
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, btndisplay, ...rest } = props;
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.ThemeMode);

  return (
    <AppBar {...rest}>
      <Toolbar className={clsx(classes.root)}>
        <Hidden lgUp>
          <IconButton style={{ color: theme.palette.topbar.iconDefault }} onClick={onSidebarOpen}>
            <Icon>menu</Icon>
          </IconButton>
        </Hidden>

        <Hidden mdDown>
          <Avatar className={classes.avatar} alt="DirectaChile" src="/images/logo.png" />
        </Hidden>

        <div className={classes.flexGrow} />

        <Box display="flex" justifyContent="center" className={classes.menu}></Box>

        <Box>
          <Tooltip title="Alterar tema claro/oscuro">
            <IconButton
              className={classes.signOutButton}
              color="inherit"
              onClick={() => {
                dispatch({ type: 'CHANGE_MODE_THEME' });
              }}
            >
              <Icon style={{ color: themeMode === 'light' ? grey[100] : yellow['A700'] }}>
                {themeMode === 'light' ? 'dark_mode' : 'light_mode'}
              </Icon>
            </IconButton>
          </Tooltip>
        </Box>

        <Box>
          <Link to="/account" underline={'none'} component={RouterLink}>
            <Tooltip title="Tus datos personales">
              <IconButton className={classes.signOutButton} color="inherit">
                <Icon style={{ color: lightBlue['A200'] }}>account_circle</Icon>
              </IconButton>
            </Tooltip>
          </Link>

          <Tooltip title="Cerrar sesión">
            <IconButton
              className={classes.signOutButton}
              color="inherit"
              onClick={() => {
                dispatch({
                  type: 'LOGOUT',
                });
                history.push('/');
              }}
            >
              <Icon style={{ color: red['A200'] }}>exit_to_app</Icon>
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
