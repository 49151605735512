const defaultState = localStorage.getItem("userData") == null ? false : JSON.parse(localStorage.getItem("userData"));

function ReducerDataUser(state = defaultState, action) {
  switch (action.type) {
    case "SET_DATA_USER": {
      localStorage.setItem("userData", JSON.stringify(action.payload));
      return action.payload;
    }

    case "GET_DATA_USER": {
      // Se obtiene el estado actual de la session
      return JSON.parse(localStorage.getItem("userData"));
    }

    case "UPDATE_KEY_DATA_USER": {
      // Se valida, que exista el payload
      if (action.payload) {
        // Se actualiza la informacion
        state[action.payload.key] = action.payload.value;
      }
      // Se limpia el state
      let tmp = Object.assign({}, state);
      // Se actualiza el localhost con los valores
      localStorage.setItem("userData", JSON.stringify(tmp));

      return tmp;
    }

    default:
      return state;
  }
}

export default ReducerDataUser;
