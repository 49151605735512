import React, { forwardRef, Fragment } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.menu.background,
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 10,
    paddingLeft: 10,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.menu.text,
    padding: '6px 8px',
    paddingLeft: 15,
    justifyContent: 'flex-start',
    textTransform: 'none',
    fontSize: 12,
    letterSpacing: 0.5,
    width: '100%',
    // borderTopRightRadius: '20px',
    // borderBottomRightRadius: '20px',
    // borderTopLeftRadius: '20px',
    // borderBottomLeftRadius: '20px',
    borderRadius: '20px !important',

    '&:hover': {
      color: theme.palette.menu.textHover,
      backgroundColor: theme.palette.menu.hover,
      '& $icon': {
        color: theme.palette.menu.textHover,
      },
    },
  },
  icon: {
    color: theme.palette.menu.text,
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    marginRight: 5,
    paddingRight: 5,
    fontSize: 1.3,

    '& .material-icons': {
      fontSize: 24,
      // width: 30,
      // height: 30,
    },
  },
  active: {
    cursor: 'default',
    color: `${theme.palette.menu.textActive} !important`,
    backgroundColor: `${theme.palette.menu.active} !important`,
    // fontWeight: theme.typography.fontWeightBold,
    '& $icon': {
      color: `${theme.palette.menu.textActive} !important`,
    },
  },
}));

const CustomRouterLink = forwardRef((props, ref) => {
  return (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  );
});

const SidebarNav = (props) => {
  const { pages, className, onClose, ...rest } = props;
  const classes = useStyles();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map((category, key) => (
        <Fragment key={key}>
          {category.map((pageMenu) => (
            <ListItem className={classes.item} disableGutters key={pageMenu.title}>
              <Link
                underline="none"
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to={pageMenu.href}
                onClick={onClose}
              >
                <div className={classes.icon}>{pageMenu.icon}</div>
                {pageMenu.title}
              </Link>
            </ListItem>
          ))}
        </Fragment>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;
