import React, { Fragment, lazy, Suspense } from 'react';
import { Route, Router, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { createBrowserHistory } from 'history';
import { Main as MainLayout } from '../layouts';
import RouteWithLayout from '../componentsReusable/RouteWithLayout';
import { useSelector } from 'react-redux';
import { PreloaderSuspense } from '../componentsReusable/PreloaderSuspense/ProloaderSuspense';

import {
  // Vista NotFound
  NotFound as NotFoundView,
} from './../views/NotFound';

const SignInView = lazy(() => import('../views/Access/SignIn'));
const RestorePasswordView = lazy(() => import('../views/Access/RestorePassword'));

const ViewAccount = lazy(() => import('../views/Auth/Account'));

const ViewPriceQuotes = lazy(() => import('../views/Auth/Admin/PriceQuotes'));
const ViewUsers = lazy(() => import('../views/Auth/Admin/Users'));
const ViewProducts = lazy(() => import('../views/Auth/Admin/Products'));
const ViewComplementaryProducts = lazy(() => import('../views/Auth/Admin/ComplementarysProducts'));
const ViewArticles = lazy(() => import('../views/Auth/Admin/Articles'));
const ViewProvider = lazy(() => import('../views/Auth/Admin/Provider'));
const ViewUsersRemoved = lazy(() => import('../views/Auth/Admin/UsersRemoved'));
const ViewProductsRemoved = lazy(() => import('../views/Auth/Admin/ProductsRemoved'));

const ViewPriceQuotesManage = lazy(() => import('../views/Auth/Contributor/PriceQuotesManage'));

const browserHistory = createBrowserHistory();

const Routes = () => {
  const StateUserRedux = useSelector((state) => state.StatusAccess);
  // const themeMode = useSelector((state) => state.ThemeMode);

  return (
    <Fragment>
      <CssBaseline />

      <Router history={browserHistory}>
        <BrowserRouter basename="">
          {StateUserRedux.state && <MainLayout />}

          <Suspense fallback={<PreloaderSuspense />}>
            <Switch>
              {!StateUserRedux.state && <Route component={RestorePasswordView} path="/restore-password" />}

              <Route component={SignInView} exact path="/" />
              <Route component={NotFoundView} exact path="/404" />

              <RouteWithLayout
                exact
                role={['admin', 'contributor']}
                component={ViewAccount}
                path="/account"
              />

              <RouteWithLayout exact role={['admin']} component={ViewPriceQuotes} path="/price-quotes" />
              <RouteWithLayout exact role={['admin']} component={ViewUsers} path="/users" />
              <RouteWithLayout exact role={['admin']} component={ViewProducts} path="/products" />

              <RouteWithLayout
                exact
                role={['admin']}
                component={ViewComplementaryProducts}
                path="/add-on_products"
              />

              <RouteWithLayout exact role={['admin']} component={ViewArticles} path="/articles" />
              <RouteWithLayout exact role={['admin']} component={ViewProvider} path="/provider" />
              <RouteWithLayout exact role={['admin']} component={ViewUsersRemoved} path="/users-removed" />
              <RouteWithLayout
                exact
                role={['admin']}
                component={ViewProductsRemoved}
                path="/products-removed"
              />

              <RouteWithLayout
                exact
                role={['contributor']}
                component={ViewPriceQuotesManage}
                path="/price-quotes-manager"
              />

              <RouteWithLayout exact component={NotFoundView} path="/not-found" />

              <Redirect from="*" to="/404" />
            </Switch>
          </Suspense>
        </BrowserRouter>
      </Router>
    </Fragment>
  );
};

export default Routes;
