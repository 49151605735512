import React from 'react';
import RoutesApp from './routes';
import ThemeApp from './componentsReusable/ComponentTheme';
import { SnackBarProvider } from './componentsReusable/SnackBar';

import './assets/styles.sass';

// Redux
import { Provider } from 'react-redux';
import store from './redux/store';

window.NAME_APP = 'App DirectaChile';

function App() {
  return (
    <Provider store={store}>
      <ThemeApp>
        <SnackBarProvider>
          <RoutesApp />
        </SnackBarProvider>
      </ThemeApp>
    </Provider>
  );
}

export default App;
