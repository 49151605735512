import { colors, fade, lighten } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
const backgroundNight = lighten(colors.grey[900], 0.02);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  black,
  white,
  primary: {
    contrastText: black,
    dark: colors.lightBlue['A700'],
    main: colors.lightBlue['A400'],
    light: colors.lightBlue['A200'],
  },
  secondary: {
    contrastText: white,
    dark: colors.pink['A700'],
    main: colors.pink['A400'],
    light: colors.pink['A200'],
  },
  success: {
    contrastText: white,
    dark: colors.green['A700'],
    main: colors.green['A400'],
    light: colors.green['A200'],
  },
  info: {
    contrastText: white,
    dark: colors.blue['A700'],
    main: colors.blue['A400'],
    light: colors.blue['A200'],
  },
  warning: {
    contrastText: white,
    dark: colors.orange['A700'],
    main: colors.orange['A400'],
    light: colors.orange['A200'],
  },
  error: {
    contrastText: white,
    dark: colors.red['A700'],
    main: colors.red['A400'],
    light: colors.red['A200'],
  },
  text: {
    primary: colors.grey[100],
    secondary: colors.grey[300],
    link: colors.blue[200],
  },
  background: {
    default: backgroundNight,
    paper: colors.grey[900],
  },
  popper: colors.grey[400],
  icon: colors.blueGrey[100],
  iconAdd: colors.lightGreen['500'],
  iconHelp: colors.yellow['300'],
  iconInfo: colors.cyan['A700'],
  iconAddPercentage: colors.blueGrey['200'],
  iconEdit: colors.orange['A200'],
  iconLock: colors.deepOrange['400'],
  iconRemove: colors.red['400'],
  iconDisabled: colors.grey['500'],
  table: colors.lightBlue['A200'],
  divider: colors.grey[900],
  avatar: colors.grey[300],
  topbar: {
    selected: colors.blue[900],
    selectedCheckbox: fade(colors.blueGrey['900'], 0.9),
    iconActionsIn: colors.teal['A400'],
    background: backgroundNight,
    contrastText: colors.grey[200],
    boxShadow: `0px 2px 4px -1px rgba(80,80,80,0.2), 0px 4px 5px 0px rgba(80,80,80,0.14), 0px 1px 10px 0px rgba(80,80,80,0.22)`,
  },
  menu: {
    text: colors.grey[400],
    background: backgroundNight,
    hover: `${fade(colors.grey[800], 0.2)}`,
    textHover: colors.grey[200],
    active: `${fade(colors.grey[600], 0.2)}`,
    textActive: colors.cyan['A200'],
  },
  avatarHover: `${fade(colors.grey[50], 0.95)}`,
};
