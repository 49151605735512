const defaultState =
  localStorage.getItem('access_true') == null
    ? {
        state: false,
        message: null,
      }
    : JSON.parse(localStorage.getItem('access_true'));

function ReducerAccess(state = defaultState, action) {
  switch (action.type) {
    case 'LOGIN': {
      localStorage.setItem(
        'access_true',
        JSON.stringify({
          state: true,
          message: null,
        })
      );
      // console.log('Se creado el estado del redux');

      return {
        state: true,
        message: null,
      };
    }
    case 'LOGOUT': {
      let mode = localStorage.getItem('theme');
      mode = mode === null || mode === undefined || mode === 'light' ? 'light' : 'dark';

      // Se eliminan las sessiones locales cuando se hace un logout
      localStorage.setItem(
        'access_true',
        JSON.stringify({
          state: false,
          message: null,
        })
      );

      setTimeout(() => {
        localStorage.clear();
        localStorage.setItem('theme', mode);
      }, 100);

      return {
        state: false,
        message: action.payload ? (action.payload.message ? action.payload.message : null) : null,
        variant: action.payload ? (action.payload.variant ? action.payload.variant : null) : null,
      };
    }

    default:
      // Retorno por defecto del redux
      return { ...state };
  }
}

export default ReducerAccess;
