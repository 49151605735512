import { createStore, combineReducers } from 'redux';
import StatusAccess from './reducers/reducer.access';
import DataUser from './reducers/reducer.dataUser';
import ThemeMode from './reducers/reducer.theme';

const reducer = combineReducers({
  StatusAccess,
  DataUser,
  ThemeMode,
});

const store = createStore(reducer);

export default store;
