import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Avatar, Divider } from '@material-ui/core';

import { SidebarNav } from './components';
import listMenu from './listMenu';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    marginTop: 0,
    borderRight: `1px solid ${theme.palette.menu.hover}`,
    flexShrink: 0,
    zindex: 9,
    [theme.breakpoints.up('lg')]: {
      // marginTop: 68,
      height: 'calc(100% - 0px)',
    },
  },
  root: {
    backgroundColor: theme.palette.menu.background,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    // padding: theme.spacing(2),
  },
  avatar: {
    borderRadius: 0,
    width: '100%',
    height: 45,
    marginTop: 10,

    '& > img': {
      width: 'auto',
    },
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = (props) => {
  const classes = useStyles();
  const { open, variant, onClose, className, displaymenu, ...rest } = props;
  const DataUserRedux = useSelector((state) => state.DataUser);

  const [pages, setPages] = useState([]);
  useEffect(() => {
    let page = [];

    page.push(listMenu[`pages_${DataUserRedux.roleCode}`]);

    setPages(page);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displaymenu]);

  return (
    <Drawer anchor="left" classes={{ paper: classes.drawer }} onClose={onClose} open={open} variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        {/* <Hidden xlUp> */}
        <Avatar className={classes.avatar} alt="DirectaChile" src="/images/logo.png" />
        {/* </Hidden> */}

        {/* <Profile /> */}
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} onClose={onClose} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
