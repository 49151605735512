import { colors, fade } from '@material-ui/core';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  contained: {
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    backgroundColor: '#FFFFFF',

    '&.Mui-disabled': {
      // ${fade(colors.grey[50], 0.95)}
      backgroundColor: `${fade(colors.grey[50], 0.18)}`,
      color: `${fade(colors.grey[50], 0.3)}`,
    },
  },

  outlined: {
    '&.Mui-disabled': {
      color: `${fade(colors.grey[50], 0.3)}`,
      border: `1px solid rgba(255, 255, 255, 0.12)`,
    },
  },
};
