import { colors, lighten } from '@material-ui/core';

import MuiDialogContent from './MuiDialogContent';
import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTablePagination from './MuiTablePagination';
import MuiTableRow from './MuiTableRow';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';
import MuiTooltip from './MuiTooltip';
import palette from '../palette';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  MuiDialogContent,
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTablePagination,
  MuiTableRow,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,

  MuiTable: {
    stickyHeader: {
      borderCollapse: 'collapse',
    },
  },

  MuiTooltip,
  MuiDialog: {
    paper: {
      background: colors.grey[800],
      border: `1px solid rgba(100,100,100,0.1)`,
    },

    paperFullScreen: {
      background: lighten(colors.grey[900], 0.05),
    },
  },

  MuiDialogTitle: {
    root: {
      backgroundColor: palette.topbar.background,
      color: palette.topbar.contrastText,

      '& .MuiTypography-root, .MuiButtonBase-root': {
        color: palette.topbar.contrastText,
      },

      '& .MuiButtonBase-root': {
        '&.Mui-disabled': {
          color: 'rgba(255, 255, 255, 0.36) !important',
        },
      },
    },
  },

  MuiList: {
    root: {
      background: colors.grey[800],
    },
  },

  MuiInputBase: {
    root: {
      '&.Mui-disabled': {
        color: `${colors.grey[600]}`,
        // border: `1px solid ${colors.grey[500]}`,
      },
    },

    input: {
      caretColor: 'white',
      '&:-webkit-autofill': {
        WebkitTextFillColor: colors.grey[100],
        WebkitBoxShadow: '0 0 0 1000px transparent inset',
      },

      '&.Mui-disabled': {
        cursor: 'not-allowed !important',
      },
    },
  },

  MuiFormLabel: {
    root: {
      '&.Mui-disabled': {
        color: colors.grey[100],
      },
    },
  },

  MuiAlert: {
    standardSuccess: {
      color: 'rgb(183, 223, 185)',
      backgroundColor: 'rgb(7, 17, 7)',
      border: '1px solid rgba(155, 155, 155, 0.55)',
    },

    standardWarning: {
      color: 'rgb(255, 213, 153)',
      backgroundColor: 'rgb(25, 15, 0)',
      border: '1px solid rgba(155, 155, 155, 0.55)',
    },

    standardInfo: {
      color: 'rgb(166, 213, 250)',
      backgroundColor: 'rgb(3, 14, 24)',
      border: '1px solid rgba(155, 155, 155, 0.55)',
    },

    standardError: {
      color: 'rgb(250, 179, 174)',
      backgroundColor: 'rgb(24, 6, 5)',
      border: '1px solid rgba(155, 155, 155, 0.55)',
    },
  },

  MuiInput: {
    underline: {
      '&:before': {
        borderBottom: '1px solid rgba(255, 255, 255, 0.72)',
      },

      '&.Mui-disabled': {
        border: `0px solid ${colors.grey[500]}`,

        '&:before': {
          borderBottomStyle: 'dotted',
        },
      },
    },
  },

  MuiOutlinedInput: {
    root: {
      '&.Mui-disabled': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(129, 129, 129, 0.3)',
        },
      },
    },
    notchedOutline: {
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
  },

  MuiListItem: {
    button: {
      '&:hover': {
        backgroundColor: colors.grey[700],
      },
    },

    root: {
      '& .MuiIconButton-root': {
        padding: '7px',
      },

      '& .MuiIcon-root': {
        fontSize: '1.2rem',
      },
    },
  },
};
