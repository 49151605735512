let defaultState = localStorage.getItem('theme');
defaultState =
  defaultState === null || defaultState === undefined || defaultState === 'light' ? 'light' : 'dark';

function ReducerThemeMode(state = defaultState, action) {
  switch (action.type) {
    case 'CHANGE_MODE_THEME': {
      let mode = state === null || state === undefined || state === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', mode);
      return mode;
    }

    default:
      return state;
  }
}

export default ReducerThemeMode;
