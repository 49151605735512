import React from 'react';
import { Icon } from '@material-ui/core';

const pages_admin = [
  // {
  //   title: 'Dashboard',
  //   href: '/dashboard',
  //   icon: <Icon>dashboard</Icon>,
  // },
  {
    title: 'Cotizaciones',
    href: '/price-quotes',
    icon: <Icon>assignment</Icon>,
  },
  {
    title: 'Productos',
    href: '/products',
    icon: <Icon>local_grocery_store</Icon>,
  },
  {
    title: 'Colaboradores',
    href: '/users',
    icon: <Icon>groups</Icon>,
  },
  {
    title: 'Artículos',
    href: '/articles',
    icon: <Icon>article</Icon>,
  },
  {
    title: 'Complementos para productos',
    href: '/add-on_products',
    icon: <Icon>widgets</Icon>,
  },
  {
    title: 'Proveedores',
    href: '/provider',
    icon: <Icon>store</Icon>,
  },
  {
    title: 'Colaboradores eliminados',
    href: '/users-removed',
    icon: <Icon>person_off</Icon>,
  },
  {
    title: 'Productos eliminados',
    href: '/products-removed',
    icon: <Icon>remove_shopping_cart</Icon>,
  },
];

const pages_contributor = [
  {
    title: 'Gestión de cotizaciones',
    href: '/price-quotes-manager',
    icon: <Icon>assignment</Icon>,
  },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  pages_admin,
  pages_contributor,
};
