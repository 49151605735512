import { colors, lighten } from '@material-ui/core';
import typography from '../typography';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  root: {
    ...typography.body1,
    borderBottom: `1px solid ${lighten(colors.grey[900], 0.16)}`,
  },
  sizeSmall: {
    padding: '6px 16px 6px 16px',
  },
};
