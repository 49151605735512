import { colors, lighten } from '@material-ui/core';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  root: {
    background: lighten(colors.grey[900], 0.04),

    '&$selected': {
      backgroundColor: lighten(colors.grey[900], 0.08),
    },

    '& .box-container-icon': {
      opacity: '0',
    },

    '&.rowAction.Mui-selected': {
      '& .box-container-icon': {
        opacity: '1',
      },
    },

    '&.rowTableHover': {
      borderTop: `2px solid ${lighten(colors.grey[900], 0.3)}`,
    },

    '&$hover': {
      '&:hover': {
        backgroundColor: lighten(colors.grey[900], 0.05),

        '& .box-container-icon': {
          opacity: '1',
        },
      },
    },
  },

  head: {
    background: lighten(colors.grey[900], 0.02),
  },
};
