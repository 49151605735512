import palette from '../palette';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  root: {
    color: palette.icon,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
    },

    '&.Mui-disabled': {
      color: 'rgba(100, 100, 100, 0.36)',
    },
  },
};
