import React, { forwardRef } from 'react';
import { Box, Link, Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 'calc(100vh - 86px)',
  },

  screenComplete: {
    height: '100vh',
  },
}));

const CustomRouterLink = forwardRef((props, ref) => {
  return <RouterLink ref={ref} {...props} />;
});

const ComponetNotFound = (props) => {
  document.title = `404 | ${window.NAME_APP}`;
  const StateUserRedux = useSelector((state) => state.StatusAccess);
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={clsx(classes.root, {
        [classes.screenComplete]: !StateUserRedux.state,
      })}
    >
      <div className="textPageNot-Found">
        <h1>404</h1>
        <h2>La página que estás buscando no está aquí 😭</h2>
        <h3>¡Ooooups! Parece que te perdiste.🙄</h3>

        <Box mt={1}>
          <Typography component="div" className="subtitle-desc">
            {StateUserRedux.state ? (
              'Selecciona una opción en el menú lateral'
            ) : (
              <Link component={CustomRouterLink} to={'/'}>
                {!StateUserRedux.state ? 'Para acceder debes iniciar sesion 🚀' : 'Revisa el menú lateral'}
              </Link>
            )}
          </Typography>
        </Box>
      </div>
    </Grid>
  );
};

export default ComponetNotFound;
