import React, { memo, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import jwt_decode from 'jwt-decode';
import { SnackBarVisible } from '../SnackBar';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      overflowY: 'auto',
      padding: 10,
      height: 'calc(100vh - 66px)',
    },
    shiftContent: {
      paddingLeft: 240,
      marginLeft: 15,
    },
  })
);

const RouteWithLayout = (props) => {
  const theme = useTheme();
  const classes = useStyles();

  const { layout: Layout, component: Component, ...rest } = props;
  const NotiPopUp = useContext(SnackBarVisible);
  const history = useHistory();
  const dispatch = useDispatch();
  const StateAccessRedux = useSelector((state) => state.StatusAccess);
  const DataUserRedux = useSelector((state) => state.DataUser);

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  useEffect(() => {
    // Se valida si existe el estado en el redux
    if (StateAccessRedux.state) {
      try {
        let decoded = jwt_decode(localStorage.getItem('token'));

        var current_time = parseInt(new Date().getTime() / 1000);
        if (current_time <= decoded.exp) {
          if (props.role) {
            // No existe error en la decodificacion del token
            if (!props.role.includes(DataUserRedux.roleCode)) {
              console.log(
                '%cNo tienes los privilegios para ingresar a esta página!',
                'background: #222; color: #ff6868'
              );
              history.push('/not-found');
            }
          }
        } else {
          // Se hace un logout de la sesion en la plataforma
          dispatch({
            type: 'LOGOUT',
            payload: {
              message: 'Ha finalizado tu sesión en la plataforma <ic>😓</ic>',
              variant: 'warning',
            },
          });
        }
      } catch (error) {
        // Se hace un logout de la sesion en la plataforma
        dispatch({
          type: 'LOGOUT',
          payload: {
            message: 'Token inválido para navegar en la aplicación <ic>😓</ic>',
            variant: 'error',
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Component]);

  useEffect(() => {
    if (!StateAccessRedux.state) {
      if (StateAccessRedux.message) {
        NotiPopUp.show({
          autoHideDuration: 6000,
          message: StateAccessRedux.message,
          variant: StateAccessRedux.variant || 'warning',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [StateAccessRedux]);

  if (!StateAccessRedux.state) {
    return <Redirect to="/" />;
  } else {
    return (
      <Route
        {...rest}
        render={(matchProps) =>
          StateAccessRedux.state ? (
            <div
              className={clsx({
                [classes.root]: true,
                [classes.shiftContent]: isDesktop,
              })}
            >
              <Component {...matchProps} />
            </div>
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }
};

export default memo(RouteWithLayout);
