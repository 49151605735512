import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

export const PreloaderSuspense = () => {
  return (
    <Box mt={5} height={30} display="flex" justifyContent="center">
      <CircularProgress size={20} color="primary" />
    </Box>
  );
};
