import React, { createContext, useState } from 'react';
import { Fade, Icon, IconButton, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

export const Context = createContext({});

function Alert(props) {
  return <MuiAlert elevation={6} {...props} />;
}

export const Provider = (props) => {
  // Initial values are obtained from the props
  const { children } = props;

  const [snack, setSnackbar] = useState({});

  const show = (params) => {
    setSnackbar((state) => ({
      ...state,
      ...params,
      open: true,
      key: new Date().getTime(),
    }));
  };

  const handleClose = (event, reason) => {
    // if (reason === 'clickaway') return;

    setSnackbar((state) => ({
      ...state,
      open: false,
    }));
  };

  // Make the context object:
  const usersContext = {
    show,
    handleClose,
  };

  // pass the value in provider and return
  return (
    <Context.Provider value={usersContext}>
      {children}

      <Snackbar
        {...snack}
        className="ec_notificationPush"
        key={snack ? snack.key : undefined}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        TransitionComponent={Fade}
        onClose={handleClose}
        onExited={handleClose}
      >
        <Alert
          // variant="outlined"
          action={
            <IconButton color="inherit" size="small" onClick={handleClose}>
              <Icon style={{ fontSize: 18 }}>close</Icon>
            </IconButton>
          }
          severity={snack.variant || 'info'}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: snack.message ? snack.message : 'Error inesperado, intente más tarde',
            }}
          ></div>
        </Alert>
      </Snackbar>
    </Context.Provider>
  );
};

export const { Consumer } = Context;
