import React, { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { theme, themeDark } from './../../theme';
import { useSelector } from 'react-redux';

const ThemeApp = (props) => {
  const themeMode = useSelector((state) => state.ThemeMode);

  // if(themeMode==='light')
  // document.body.classList.add('modal-open');

  useEffect(() => {
    document.body.className = themeMode === 'dark' ? 'theme-dark-app' : false;
  }, [themeMode]);

  return <ThemeProvider theme={themeMode === 'light' ? theme : themeDark}>{props.children}</ThemeProvider>;
};

export default ThemeApp;
