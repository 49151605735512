import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Sidebar, Topbar } from './components';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      background: theme.palette.background.default,
      // marginTop: '64px',
      paddingTop: 64,
      [theme.breakpoints.up('sm')]: {
        paddingTop: 64,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    shiftContent: {
      paddingLeft: 240,
    },
    drawerPaper: {
      width: 240,
    },
  })
);

const Main = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
    </div>
  );
};

export default Main;
