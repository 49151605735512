import { colors } from '@material-ui/core';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  root: {
    backgroundColor: colors.grey[800],
  },

  elevation1: {
    background: colors.blueGrey[900],
    boxShadow: '0 0 0px 2px rgba(100,100,100,0.15), 0 1px 2px 0 rgba(100,100,100,0.35)',
  },
};
